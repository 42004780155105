
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})

export default class HintTooltipVue extends Vue {
  @Prop({ type: String, default: '' })
  public copy!: string
  @Prop({ type: String, default: '' })
  public title!: string
  @Prop({ type: Number, default: 5000 })
  public timeout!: number
  @Prop({
    type: String,
    default: 'left',
    validator: (value: string) => ['left', 'right', 'top', 'bottom'].includes(value),
  })
  public position!: string
  @Prop({
    type: String,
    default: 'rt',
    validator: (value: string) => ['tl', 'tr', 'tc', 'bl', 'br', 'bc', 'lt', 'lb', 'lc', 'rt', 'rb', 'rc'].includes(value),
  })
  public arrowPosition!: string

  private isHintTooltipActive: boolean = false
  private hintTooltipTimeoutID: ReturnType<typeof setTimeout> | undefined

  public showHintTooltip() {
    this.isHintTooltipActive = true

    this.hintTooltipTimeoutID = setTimeout(() => {
      this.hideHintTooltip()
    }, this.timeout)
  }

  public hideHintTooltip() {
    this.isHintTooltipActive = false
    clearTimeout(this.hintTooltipTimeoutID)
  }

  public toggleHintTooltip() {
    if (this.isHintTooltipActive) {
      this.hideHintTooltip()
    } else {
      this.showHintTooltip()
    }
  }
}
