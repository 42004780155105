
import { StandardObject } from '@/store/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { StyleValue } from 'vue/types/jsx'

@Component({})

export default class OffsetVue extends Vue {
  @Prop({ type: Boolean, default: false })
  public fullWidth!: boolean
  @Prop({ type: [Number, String], default: 0 })
  public offset!: number | string

  private get classes(): StandardObject {
    return {
      'v-offset--full-width': this.fullWidth,
    }
  }

  private get styles(): StyleValue {
    return {
      top: `-${this.offset}px`,
      marginBottom: `-${this.offset}px`,
    }
  }
}
