
import TenantSwitcher from '@/components/modals/TenantSwitcher.vue'
import IconSignIn from '@/components/svg/IconSignIn.vue'
import IconSignOut from '@/components/svg/IconSignOut.vue'
import IconSwitch from '@/components/svg/IconSwitch.vue'
import IconUser from '@/components/svg/IconUser.vue'
import { Tenant } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { mapMutations, mapState } from 'vuex'
import { Mutation, State } from 'vuex-class'

declare var consoleLog : any

@Component({
  components: {
    'tenant-switcher': TenantSwitcher,
    'icon-user': IconUser,
    'icon-switch': IconSwitch,
    'icon-signout': IconSignOut,
    'icon-signin': IconSignIn,
  },
  computed: {
    ...mapState('utils', ['color']),
  },
  watch: {
    $route(val: any) {
      this.title = val.name
    },
  },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('utils', ['toggleDrawer']),
  },
})

export default class AppBarVue extends Vue {
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant

  @Mutation('setDrawer', { namespace: Namespace.Utils })
  public setDrawer: any

  public $refs!: Vue['$refs'] & {
    tenantSwitcherDialog: TenantSwitcher,
  }

  public notifications = [
    'Mike, John responded to your email',
    'You have 5 new tasks',
    'You\'re now a friend with Andrew',
    'Another Notification',
    'Another One',
  ]
  public isAuthenticated = false
  public responsive = false
  public responsiveInput = false
  public profile: any
  public color: any

  protected mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    // Re-run login logic because this component may not have mounted before login event was fired from $auth
    this.handleLoginEvent({
      loggedIn: this.$auth0.isAuthenticated,
      profile: this.$auth0.user,
    })
  }

  public async handleLoginEvent(data: any) {
    this.isAuthenticated = data.loggedIn
    this.profile = data.profile
  }

  public onClickBtn() {
    this.setDrawer(!this.$store.state.utils.drawer)
  }

  public showTenantSwitcherDialog() {
    this.$refs.tenantSwitcherDialog.ShowDialog()
  }

  public logout() {
    // Emit on-logout event to fire any function to run before logout. i.e. tracking.
    this.$emit('on-logout', () => {
      // We need to wait till any other event to be done before we logout.
      // At this stage it will be the identify tracking event needs to be run before logout.
      consoleLog('Start logout process')
      Vue.prototype.$auth0.logout()
      this.$router.push({ path: '/' })
    })
  }

  protected onResponsiveInverted() {
    if (window.innerWidth < 991) {
      this.responsive = true
      this.responsiveInput = false
    } else {
      this.responsive = false
      this.responsiveInput = true
    }
  }
}
