
import IconInfo from '@/components/svg/IconInfo.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    'icon-info': IconInfo,
  },
})

export default class NotificationVue extends Vue {
}
