import { getInstance } from '@/plugins/auth0'

export const authenticationGuard = (to, from, next) => {
  const authService = getInstance()

  const guardAction = () => {
    // Any route with #error= is an error from an external callback so go to /oops
    if (to.path !== '/oops' && to.hash.startsWith('#error=')) {
      document.title = `${to.name} | ${process.env.VUE_APP_TITLE}`

      if (!authService.isAuthenticated) {
        authService.clearPII()
      }

      return next(`/oops${to.hash}`)
    }

    if (to.path === '/' ||
      (to.meta !== undefined && !to.meta.loginRequired) ||
      authService.isAuthenticated) {
      document.title = `${to.name} | ${process.env.VUE_APP_TITLE}`

      if (!authService.isAuthenticated) {
        authService.clearPII()
      }

      return next()
    }

    consoleLog(`At Router.beforeEach calling auth.login with target ${to.fullPath}`)
    authService.clearPII()
    authService.loginWithRedirect({
      appState: { targetPath: to.fullPath },
      prompt: 'login',
      authorizationParams: {
        allowSignUp: false,
      },
    })
  }

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction()
  }

  authService.$watch('isLoading', (isLoading) => {
    if (isLoading === false) {
      return guardAction()
    }
  })
}
