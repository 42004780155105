
// Utilities
import { Namespace } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'

@Component({})

export default class FilterVue extends Vue {
  @State('image', { namespace: Namespace.Utils })
  public image!: string
  @State('color', { namespace: Namespace.Utils })
  public color!: string

  @Mutation('setImage', { namespace: Namespace.Utils })
  public setImage: any
  @Mutation('setColor', { namespace: Namespace.Utils })
  public setColor: any

  private colors: string[] = ['primary', 'info', 'success', 'warning', 'danger']
  private images: string[] = [
    'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg',
    'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-3.3a54f533.jpg',
    'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-4.3b7e38ed.jpg',
  ]
}
