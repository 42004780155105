
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})

export default class PlanTagVue extends Vue {
  @Prop({ required: true, type: String, default: '' })
  public copy!: string
  @Prop({ type: String, default: '' })
  public icon!: string
  @Prop({ type: String, default: 'success-900' })
  public iconColor!: string
}
